import {
    formInputs,
    FormProvider,
    useLocalFormState,
} from '@gnist/design-system';
import {
    mustBeChecked,
    noValidation,
    required,
    validEmail,
    validZipCode,
} from '@gnist/design-system/utilities/validation';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { FormButtons } from './FormButtons';
import {
    breakpointDesktop,
    breakpointTablet,
} from '@gnist/design-system/utilities/layout';
import { useGetCity } from '../../utils/useGetCity';
import { SubTitle } from './CarStep';
import { sortedDealers } from '../../utils/sortedDealers';
import { DealerModel } from '@/types/DealerModel';
import { useGetUrlParams } from '../../utils/useGetUrlParams';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';
import { CustomerRole } from '@/utils/enums/CustomerRole';
import { FormRadioGroup } from '@/views/my-page-contract/components/FormRadioGroup';
import { initialBooleanValue } from '@/views/self-declaration/utils/selfDeclarationFormUtils';
import { tokens } from '@gnist/themes/tokens.css.js';

export type CustomerStepFormModel = {
    firstName: string;
    lastName: string;
    email: string;
    zipCode: string;
    dealerNumber: string;
    acceptedTerms: boolean;
    isRegisteredOwner: boolean | undefined;
    isPrivatePerson: boolean | undefined;
};

export const initialCustomerStepFormValues: CustomerStepFormModel = {
    firstName: '',
    lastName: '',
    email: '',
    zipCode: '',
    dealerNumber: '',
    acceptedTerms: false,
    isRegisteredOwner: undefined,
    isPrivatePerson: undefined,
};

const { TextField, Checkbox, SingleSelect } =
    formInputs<CustomerStepFormModel>();

const InputLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: ${tokens.spacing.xl};
    row-gap: ${tokens.spacing.xs};
    margin-bottom: ${tokens.spacing.xs};

    .hidden-mobile {
        display: none;

        @media screen and (min-width: ${breakpointDesktop}) {
            display: flex;
        }
    }

    > span {
        flex: 1 1 auto;
    }

    @media screen and (min-width: ${breakpointTablet}) {
        > span {
            flex: 1 1 40%;
        }
    }
`;

const CompactInputLayout = styled(InputLayout)`
    row-gap: 0;
    margin-bottom: 0;

    @media screen and (min-width: ${breakpointTablet}) {
        margin-bottom: calc(-1 * ${tokens.spacing.base});
    }
`;

const TermsInfoLayout = styled.div`
    margin-top: ${tokens.spacing.xs};
    margin-bottom: ${tokens.spacing.xxs};

    a {
        color: ${tokens.palette.black};
    }
`;

interface CustomerStepProps {
    handleOnSubmit: (
        customerData: Omit<
            CustomerStepFormModel,
            'isRegisteredOwner' & 'isPrivatePerson'
        > & { customerRole: CustomerRole },
        selectedDealer?: DealerModel,
    ) => void;
    goToPreviousStep: () => void;
    dealers: DealerModel[] | undefined;
    setCustomer: (data: CustomerStepFormModel) => void;
    customer: CustomerStepFormModel;
}

export const CustomerStep = ({
    handleOnSubmit,
    goToPreviousStep,
    dealers,
    setCustomer,
    customer,
}: CustomerStepProps) => {
    const { uuidFromUrl } = useGetUrlParams();
    const { selectDealerOptional, showSelectDealer } = useFeatureFlags();
    const showPreferredDealerSelect = !!showSelectDealer;
    const dealerOptional = showSelectDealer ? !!selectDealerOptional : true;
    const form = useLocalFormState<CustomerStepFormModel>(customer, {
        firstName: noValidation(),
        lastName: noValidation(),
        email: required(validEmail),
        zipCode: required(validZipCode),
        dealerNumber: dealerOptional ? noValidation() : required(),
        acceptedTerms: mustBeChecked(i18next.t('requiredField')),
        isRegisteredOwner: required(),
        isPrivatePerson: required(),
    });
    const { city } = useGetCity(form.state.validated.zipCode);
    const selectedDealer = () => {
        const selectedDealerNumber = form.inputProps('dealerNumber').value;

        if (selectedDealerNumber) {
            return dealers?.find((d) => d.number === selectedDealerNumber);
        } else return undefined;
    };

    return (
        <FormProvider
            id="phoneNumberStepForm"
            form={form}
            onSubmit={(customerData) => {
                handleOnSubmit(
                    {
                        ...customerData,
                        customerRole: customerData.isRegisteredOwner
                            ? CustomerRole.REGISTERED_OWNER
                            : customerData.isPrivatePerson
                              ? CustomerRole.BEHALF_OF_OWNER
                              : CustomerRole.BEHALF_OF_COMPANY,
                    },
                    selectedDealer(),
                );
            }}
        >
            <CompactInputLayout>
                <TextField
                    field="firstName"
                    label={i18next.t('firstName')}
                    autoComplete="given-name"
                />
                <TextField
                    field="lastName"
                    label={i18next.t('lastName')}
                    autoComplete="family-name"
                />
            </CompactInputLayout>
            <InputLayout>
                <TextField
                    field="email"
                    label={i18next.t('email')}
                    type="email"
                    autoComplete="email"
                    inputMode="email"
                />
                <TextField
                    field="zipCode"
                    label={i18next.t('zipCode')}
                    inputMode="numeric"
                    autoComplete="postal-code"
                    suffix={city}
                />
            </InputLayout>
            {showPreferredDealerSelect && (
                <>
                    <SubTitle>{i18next.t('preferredDealer')}</SubTitle>
                    <InputLayout>
                        <SingleSelect
                            field="dealerNumber"
                            label={i18next.t('chooseDealer')}
                            options={
                                dealers
                                    ? [
                                          { value: '', label: '' },
                                          ...sortedDealers(dealers).map(
                                              (dealer) => ({
                                                  value: dealer.number,
                                                  label: dealer.name,
                                              }),
                                          ),
                                      ]
                                    : []
                            }
                        />
                        <span className="hidden-mobile" />
                    </InputLayout>
                </>
            )}
            <FormRadioGroup
                form={form}
                field="isRegisteredOwner"
                boldGroupLabel
                firstRadioOnChange={() => {
                    form.inputProps('isPrivatePerson').setValue(false);
                }}
                secondRadioOnChange={() => {
                    if (
                        form.inputProps('isPrivatePerson').value !== undefined
                    ) {
                        form.inputProps('isPrivatePerson').setValue(undefined);
                    }
                }}
            />
            {form.inputProps('isRegisteredOwner').value === false && (
                <FormRadioGroup
                    form={form}
                    field="isPrivatePerson"
                    boldGroupLabel
                    firstRadioLabel="privatePerson"
                    secondRadioLabel="company"
                />
            )}
            <SubTitle>{i18next.t('consent')}</SubTitle>
            <TermsInfoLayout>
                <p>
                    {i18next.t('termsInfoPart1')}
                    <a href="./personvern/" target="_blank" rel="noreferrer">
                        {i18next.t('termsInfoPart2')}
                    </a>
                    {i18next.t('termsInfoPart3')}
                    <a
                        href="https://mollerbil.no/personvern/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {i18next.t('termsInfoPart4')}
                    </a>
                    .
                </p>
            </TermsInfoLayout>
            <Checkbox label={i18next.t('termsLabel')} field="acceptedTerms" />
            <FormButtons
                extraMargin
                primaryAction={{
                    textKey: uuidFromUrl ? 'send' : 'sendRequest',
                }}
                secondaryAction={{
                    textKey: 'previous',
                    onClick: () => {
                        setCustomer({
                            firstName: form.state.validated.firstName || '',
                            lastName: form.state.validated.lastName || '',
                            email: form.state.validated.email || '',
                            zipCode: form.state.validated.zipCode || '',
                            dealerNumber:
                                form.state.validated.dealerNumber || '',
                            isRegisteredOwner: initialBooleanValue(
                                form.state.validated.isRegisteredOwner,
                            ),
                            isPrivatePerson: initialBooleanValue(
                                form.state.validated.isPrivatePerson,
                            ),
                            acceptedTerms:
                                form.state.validated.acceptedTerms || false,
                        });
                        goToPreviousStep();
                    },
                }}
            />
        </FormProvider>
    );
};
