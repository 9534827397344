import { useLocation } from 'react-router';
import queryString from 'query-string';

type UrlParams = {
    srcFromUrl?: string;
    regNoFromUrl?: string;
    mileageFromUrl?: string;
    purchaseHelpDesiredFromUrl?: string;
    phoneNumberFromUrl?: string;
    uuidFromUrl?: string;
};

export const useGetUrlParams = () => {
    const { search } = useLocation();
    const params = queryString.parse(search);

    return {
        srcFromUrl: params.src,
        regNoFromUrl: params.registrationNumber,
        mileageFromUrl: params.mileage,
        purchaseHelpDesiredFromUrl: params.purchaseHelpDesired,
        phoneNumberFromUrl: params.phoneNumber,
        uuidFromUrl: params.uuid,
    } as UrlParams;
};
