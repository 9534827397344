import { OfferModel, PriceOfferCustomerResponse } from '@/types/OfferModel';
import { Collapse } from '@/components/Collapse';
import { css, styled } from 'styled-components';
import {
    AlertBanner,
    breakpointDesktop,
    GhostButton,
    Heading3Text,
    InfoCard,
    SecondaryButton,
    SuccessButton,
} from '@gnist/design-system';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import { AttachmentCard } from './AttachmentCard';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import { useState } from 'react';
import { CustomerResponse } from './price-estimate/CustomerResponse';
import { useUpdatePriceOffer } from '../queries/useUpdatePriceOffer';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { ValuationStatus } from '@/utils/enums/ValuationStatus';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import parse from 'html-react-parser';
import moment from 'moment';
import { DATE_MONTH_FORMAT } from '@/utils/dateUtils';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';
import { DeclinationOfferResponseModal } from '@/views/my-page/components/DeclinationOfferResponseModal';
import { PriceBreakdown } from '@/components/PriceBreakdown';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(InfoCard)<{ $topMargin: boolean }>`
    ${(props) => css`
        ${props.$topMargin && `margin-top: ${tokens.spacing.s};`}
        background-color: ${tokens.color.primary};
    `}
`;

const Label = styled.label<{ $isDisabled: boolean }>`
    ${(props) => css`
        display: flex;
        text-transform: uppercase;
        font-size: ${tokens.typeface.size.s};
        font-weight: 500;
        color: ${props.$isDisabled
            ? tokens.color['on-disabled']
            : tokens.palette.info['60']};

        @media screen and (min-width: ${breakpointDesktop}) {
            font-size: ${tokens.typeface.size.s};
        }
    `}
`;

const PurchasePrice = styled.h2<{ $isDisabled: boolean }>`
    ${(props) => css`
        font-size: ${tokens.typeface.size.xxl};
        font-weight: 500;
        margin: 0 0 ${tokens.spacing.s};
        line-height: ${tokens.typeface['line-height']};
        color: ${props.$isDisabled
            ? tokens.color['on-disabled']
            : tokens.palette.white};
    `}
`;

const StyledCollapse = styled(Collapse)<{ $isDisabled: boolean }>`
    ${(props) => css`
        color: ${tokens.palette.white};
        ${props.$isDisabled &&
        css`
            > div {
                color: ${tokens.color['on-disabled']};
            }
        `}
    `}
`;

const Justification = styled.p`
    white-space: pre-wrap;
    color: ${tokens.color['on-primary']};
`;

const ResponseSection = styled.div`
    padding-top: ${tokens.spacing.l};
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${tokens.spacing.s};
    padding-top: ${tokens.spacing.xs};

    @media screen and (min-width: ${breakpointDesktop}) {
        flex-direction: row;
    }

    button {
        width: 100%;

        @media screen and (min-width: ${breakpointDesktop}) {
            width: calc(50% - ${tokens.spacing.xs});
        }
    }
`;

const StyledGhostButton = styled(GhostButton)`
    color: ${tokens.palette.white};
    border-color: ${tokens.palette.white};
`;

const ExpiredContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing.xs};
    padding-top: ${tokens.spacing.l};

    button {
        width: 100%;
    }
`;

const Attachments = styled.div`
    display: flex;
    width: 100%;
    row-gap: ${tokens.spacing.s};
    column-gap: ${tokens.spacing.s};
    padding-top: ${tokens.spacing.s};
    flex-wrap: wrap;
`;

const Response = styled(CustomerResponse)`
    width: 100%;
`;

const ResponseHeading = styled(Heading3Text)`
    font-size: ${tokens.typeface.size.m};
    color: ${tokens.palette.white};
`;

const DeadlineBanner = styled(AlertBanner)`
    margin-top: ${tokens.spacing.m};
`;

const Error = styled(GeneralErrorAlert)`
    margin: ${tokens.spacing.l} 0 0;
`;

interface PriceOfferProps {
    offer: OfferModel;
}

export const PriceOffer = ({ offer }: PriceOfferProps) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const [customerResponse] = useState<PriceOfferCustomerResponse | undefined>(
        valuation?.offer?.customerResponse,
    );
    const updatePriceOffer = useUpdatePriceOffer(id, offer.id);
    const { isError, isLoading } = updatePriceOffer;
    const attachment = valuation?.offer.attachment;
    const isExpired = customerResponse === 'Expired';
    const isDisabled = isExpired || customerResponse === 'RenewOffer';
    const deadlineDate = valuation?.offer.deadlineDate;
    const { showDeadlineDate } = useFeatureFlags();

    const [isOpenDeclinationModal, setOpenDeclinationModal] = useState(false);
    const [showNPSBanner, setShowNPSBanner] = useState(false);

    return (
        <>
            <DeclinationOfferResponseModal
                close={() => setOpenDeclinationModal(false)}
                showNPSBanner={() => setShowNPSBanner(true)}
                isModalOpen={isOpenDeclinationModal}
                offerId={offer.id}
            />
            <Card
                $topMargin={valuation?.status === ValuationStatus.ACCEPTED}
                transparent
            >
                {showNPSBanner && (
                    <NetPromoterScore
                        situation={
                            valuation?.showPriceBreakdown
                                ? 'OfferDeclinedShowPriceBreakdown'
                                : 'OfferDeclined'
                        }
                    />
                )}
                {isLoading && <LoadingOverlay delayMillis={0} fixed />}
                <Label $isDisabled={isDisabled}>
                    {i18next.t('weThinkWeCanOffer')}
                </Label>
                <PurchasePrice $isDisabled={isDisabled}>{`${numberFormat(
                    offer.purchasePrice,
                )} ${i18next.t('priceSuffix')}`}</PurchasePrice>
                {valuation?.showPriceBreakdown && (
                    <PriceBreakdown
                        calculation={valuation.calculation!}
                        purchasePrice={offer.purchasePrice}
                    />
                )}
                <StyledCollapse
                    id="offer-justification"
                    collapsedHeight="170"
                    $isDisabled={isDisabled}
                    backgroundColorToken={tokens.color.primary}
                    withContainer={valuation?.showPriceBreakdown}
                    alignment={
                        valuation?.showPriceBreakdown ? 'center' : undefined
                    }
                >
                    <Justification>{offer.justification}</Justification>
                </StyledCollapse>
                {attachment && (
                    <Attachments>
                        <AttachmentCard key={attachment.id} file={attachment} />
                    </Attachments>
                )}
                {showDeadlineDate &&
                    !!deadlineDate &&
                    !valuation.offer.customerResponse && (
                        <DeadlineBanner
                            type="info"
                            message={i18next.t('offerValidDeadline', {
                                date: moment(deadlineDate).format(
                                    DATE_MONTH_FORMAT,
                                ),
                            })}
                        />
                    )}
                {isExpired ? (
                    <ExpiredContent>
                        <AlertBanner
                            type="warning"
                            message={
                                parse(
                                    i18next.t('offerExpiredMessage'),
                                ) as string
                            }
                        />
                        <SecondaryButton
                            onClick={() => {
                                updatePriceOffer.mutate({
                                    customerResponse:
                                        PriceOfferCustomerResponse.RENEWOFFER,
                                });
                            }}
                        >
                            {i18next.t('RenewOffer')}
                        </SecondaryButton>
                    </ExpiredContent>
                ) : valuation?.offer.customerResponse &&
                  !isError &&
                  !isLoading ? (
                    <Response
                        customerResponse={valuation?.offer.customerResponse}
                    />
                ) : (
                    <ResponseSection>
                        <ResponseHeading>
                            {i18next.t('offerResponseHeading')}
                        </ResponseHeading>
                        <Buttons>
                            <SuccessButton
                                onClick={() => {
                                    updatePriceOffer.mutate({
                                        customerResponse:
                                            PriceOfferCustomerResponse.ACCEPTED,
                                    });
                                }}
                            >
                                {i18next.t('Accepted')}
                            </SuccessButton>
                            <StyledGhostButton
                                onClick={() => {
                                    setOpenDeclinationModal(true);
                                }}
                            >
                                {i18next.t('Declined')}
                            </StyledGhostButton>
                        </Buttons>
                    </ResponseSection>
                )}
                {isError && <Error density="compact" />}
            </Card>
        </>
    );
};
