import { useEffect, useState } from 'react';
import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';
import i18next from 'i18next';
import { css, styled } from 'styled-components';
import { ValuationType } from '@/utils/enums/ValuationType';
import { ErrorPage } from '@/components/ErrorPage';
import { useGetDealers } from './queries/useGetDealers';
import { Page } from '@/components/Page';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { useConfirmRequest } from './queries/useConfirmRequest';
import { ConfirmedPageContent } from './components/ConfirmedPageContent';
import { FormPageContent } from './components/FormPageContent';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { PageHeader } from '@/components/PageHeader';
import TagManager from 'react-gtm-module';
import { tokens } from '@gnist/themes/tokens.css.js';

interface ValuationRequestViewProps {
    readonly valuationType: ValuationType;
}

export const NewCarBoxWrapper = styled.div`
    margin-top: ${tokens.size['4xl']};
`;

export const ValuationPage = styled(Page)<{ $narrowDesktop: boolean }>`
    ${(props) => css`
        > div {
            @media screen and (min-width: ${breakpointTablet}) {
                max-width: ${props.$narrowDesktop ? 600 : 800}px;
            }
        }
    `}
`;

export const ValuationRequestView = ({
    valuationType,
}: ValuationRequestViewProps) => {
    const { data: dealers, isError: isDealerError } = useGetDealers();
    const confirmRequestMutation = useConfirmRequest(valuationType);
    const {
        data: response,
        isLoading,
        isError,
        isSuccess,
    } = confirmRequestMutation;
    const isCustomerLoginAvailable = !!response?.data.customerLoginAvailable;
    const isPriceEstimateAvailable = !!response?.data.priceEstimateAvailable;
    const [carModel, setCarModel] = useState('');

    const pageTitle = (): string => {
        switch (valuationType) {
            case ValuationType.TRADE_IN:
                return i18next.t('valuationTradeIn');
            case ValuationType.COMPANY_CAR:
                return i18next.t('valuationCompanyCar');
            default:
                return i18next.t('sellMyCar');
        }
    };

    document.title = pageTitle();

    useEffect(() => {
        if (
            getCurrentEnvironmentName() === 'prod' &&
            isSuccess &&
            response.data
        ) {
            if (valuationType === ValuationType.CONSUMER) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'consumerFormSubmitted',
                        carModel: carModel,
                        valuationId: response.data.valuationId,
                    },
                });
            }
        }
    }, [carModel, isSuccess, valuationType, response?.data]);

    if (isDealerError) {
        return <ErrorPage />;
    }

    return (
        <>
            {isLoading && <LoadingOverlay delayMillis={0} fixed />}
            {valuationType === ValuationType.CONSUMER && (
                <PageHeader titleTextKey="sellMyCar" />
            )}
            <ValuationPage
                $narrowDesktop={
                    valuationType === ValuationType.CONSUMER ||
                    valuationType === ValuationType.TRADE_IN
                }
            >
                {isSuccess ? (
                    <ConfirmedPageContent
                        isCustomerLoginAvailable={isCustomerLoginAvailable}
                        isPriceEstimateAvailable={isPriceEstimateAvailable}
                    />
                ) : (
                    <FormPageContent
                        valuationType={valuationType}
                        dealers={dealers}
                        handleOnSubmit={(data, selectedDealer, authToken) =>
                            confirmRequestMutation.mutate({
                                requestForm: data,
                                selectedDealer,
                                authToken,
                            })
                        }
                        pageTitle={pageTitle()}
                        isError={isError}
                        setCarModel={setCarModel}
                    />
                )}
            </ValuationPage>
        </>
    );
};
