import { Page } from '@/components/Page';
import { AlertBanner, PrimaryButton } from '@gnist/design-system';
import { styled } from 'styled-components';
import i18next from 'i18next';
import { useParams, Link, useNavigate } from 'react-router';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { useSendContractSigningStatus } from './queries/useSendContractSigningStatus';
import { useEffect } from 'react';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { PageHeader } from '@/components/PageHeader';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';
import { useGetMyPageValuation } from '@/views/my-page/queries/useGetMyPageValuation';
import { ValuationType } from '@/utils/enums/ValuationType';
import TagManager from 'react-gtm-module';
import { tokens } from '@gnist/themes/tokens.css.js';

export const Banner = styled(AlertBanner)`
    margin-bottom: ${tokens.spacing.xl};

    a {
        color: ${tokens.color['on-background']};
        font-weight: 500;
    }
`;

export const MyPageContractSigned = () => {
    const { id } = useParams<{ id: string }>();
    const sendContractSigningStatus = useSendContractSigningStatus(id);
    const { data: valuation } = useGetMyPageValuation(id);
    const { isError, isLoading, isIdle, isSuccess } = sendContractSigningStatus;
    const navigate = useNavigate();

    useEffect(() => {
        if (
            getCurrentEnvironmentName() === 'prod' &&
            isSuccess &&
            valuation != null
        ) {
            if (valuation.valuationType === ValuationType.CONSUMER) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'contractSigned',
                        valuationId: valuation.valuationId,
                    },
                });
            }
        }
    }, [valuation, isSuccess]);

    useEffect(() => {
        if (!isIdle) return;
        if (id) sendContractSigningStatus.mutate('closed');
    }, [id, isIdle, sendContractSigningStatus]);

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey="signContract"
                    backButtonOnClick={() =>
                        void navigate(`${PATH_MY_PAGE}/${id}`)
                    }
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                {isIdle || isLoading ? (
                    <LoadingOverlay delayMillis={0} />
                ) : isError ? (
                    <GeneralErrorAlert />
                ) : (
                    <>
                        <Banner
                            type="success"
                            heading={i18next.t('myPageTitleContractSigned')}
                            message={i18next.t('contractSignedInfo')}
                        />
                        <PrimaryButton
                            style={{ alignSelf: 'flex-end' }}
                            $as={Link}
                            to={`${PATH_MY_PAGE}/${id || ''}`}
                        >
                            {i18next.t('goToFrontpage')}
                        </PrimaryButton>
                    </>
                )}
            </Page>
            <NetPromoterScore situation="ContractSigned" />
        </>
    );
};
