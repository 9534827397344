export interface Environment {
    envName: EnvironmentName;
    apiUrl: string;
}

type MollerEnvironment = 'prod' | 'stage' | 'test' | 'dev';
export type EnvironmentName = MollerEnvironment | 'local';

const apiUrl: Record<MollerEnvironment, string> = {
    prod: 'https://bruktbilx2-api.mollerbil.no',
    test: 'https://bruktbilx2-api.test.mollercloud.no',
    stage: 'https://bruktbilx2-api.stage.mollercloud.no',
    dev: 'https://bruktbilx2-api.dev.mollercloud.no',
};

const webUrlEnvironmentMap: Record<MollerEnvironment, string[]> = {
    prod: [
        'https://verdivurdering.moller.no',
        'https://verdivurdering.prod.mollercloud.no',
        'https://verdivurdering.mollerbil.no',
    ],
    stage: ['https://verdivurdering.stage.mollercloud.no'],
    test: ['https://verdivurdering.test.mollercloud.no'],
    dev: ['https://verdivurdering.dev.mollercloud.no'],
};

export function parseEnvironmentName(
    env: string | undefined | boolean,
): MollerEnvironment | undefined {
    switch (env) {
        case 'prod':
        case 'stage':
        case 'test':
        case 'dev':
            return env;
        default:
            return undefined;
    }
}

export function environmentNameFromLocation(
    location: Location,
): EnvironmentName | undefined {
    if (!location.origin) {
        return undefined;
    }
    const environmentName = Object.entries(webUrlEnvironmentMap)
        .map(([env, urls]) => ({ env, urls }))
        .find(({ urls }) =>
            urls.some((url) => new URL(url).origin === location.origin),
        )?.env;

    return parseEnvironmentName(environmentName);
}

export function getEnvironment(envName: EnvironmentName): Environment {
    if (envName === 'local') {
        const apiEnvironment = parseEnvironmentName(
            import.meta.env.VITE_BACKEND_ENV,
        );
        return {
            envName,
            apiUrl: apiEnvironment
                ? apiUrl[apiEnvironment]
                : 'https://localhost:7083',
        };
    }
    return {
        envName,
        apiUrl: apiUrl[envName],
    };
}
