import {
    breakpointMobile,
    Heading1Text,
    Heading2Text,
} from '@gnist/design-system';
import { atoms } from '@gnist/themes/atoms.css.js';
import { tokens } from '@gnist/themes/tokens.css.js';
import { css, styled } from 'styled-components';
import { Page } from '@/components/Page';

const PageWrapper = styled(Page)`
    padding: ${tokens.spacing.xxl} ${tokens.spacing.s} ${tokens.spacing.xxl};
    box-sizing: border-box;

    @media screen and (min-width: ${breakpointMobile}) {
        padding-top: ${tokens.spacing['3xl']};
        padding-bottom: ${tokens.size['4xl']};
    }

    a {
        color: ${tokens.palette.black};
    }
`;

const Preamble = styled.p`
    font-size: ${tokens.typeface.size.m};
    line-height: ${tokens.typeface['line-height']};
    margin-bottom: ${tokens.spacing.l};
`;

const Subtitle = ({ children }: { children: string }) => (
    <Heading2Text
        $as="h2"
        className={atoms({ marginBottom: 'xxs' })}
        style={{ fontSize: tokens.typeface.size.base }}
    >
        {children}
    </Heading2Text>
);

const Paragraph = styled.p<{ $noMargin?: boolean }>`
    ${({ $noMargin }) => css`
        margin-bottom: ${$noMargin ? 0 : tokens.spacing.l};
    `}
`;

const UnorderedList = styled.ul`
    margin-bottom: ${tokens.spacing.l};
    padding-left: ${tokens.spacing.l};
`;

export const Consent = () => {
    return (
        <PageWrapper>
            <Heading1Text $as="h1" className={atoms({ marginBottom: 'l' })}>
                Hvorfor samler vi inn personopplysninger?
            </Heading1Text>
            <Preamble>
                I all hovedsak brukes informasjonen Møller Bil (&quot;vi&quot;)
                samler inn til å lage et tilbud til deg, og for å kommunisere
                meg deg i forbindelse med bruk av tjenesten.
            </Preamble>
            <Subtitle>Hvilken informasjon lagrer vi og hvorfor?</Subtitle>
            <Paragraph $noMargin>
                For å kunne ta kontakt med deg: (Samtykke, ref. GDPR Art. 6 (1)
                a)
            </Paragraph>
            <UnorderedList>
                <li>Navn (*Frivillig)</li>
                <li>Telefonnummer</li>
                <li>E-post</li>
            </UnorderedList>
            <Paragraph $noMargin>
                For å kunne vurdere bilen din: (Samtykke, ref. GDPR Art. 6 (1)
            </Paragraph>
            <UnorderedList>
                <li>Registreringsnummer</li>
            </UnorderedList>
            <Paragraph $noMargin>
                For å kunne regne på hvordan vi eventuelt skal ta imot bilen
                din: (Samtykke, ref. GDPR Art. 6 (1)
            </Paragraph>
            <UnorderedList>
                <li>Postnummer</li>
            </UnorderedList>
            <Paragraph>
                Vi bruker også e-postadressen din til å sende deg en e-post med
                en lenke til en spørreundersøkelse etter at du har bestemt deg
                for å selge bilen til oss eller ikke. Det er helt frivillig å
                delta i spørreundersøkelsen.
            </Paragraph>
            <Paragraph>
                Ingen av personopplysningene vi samler i denne sammenheng er
                klassifisert som sensitive.
            </Paragraph>
            <Subtitle>Hvor lagrer vi personopplysningene dine?</Subtitle>
            <Paragraph>
                Vi lagrer dine opplysninger i en sikret database på Microsofts
                Azure-datasenter i Vest-Europa og på Auth0 sitt datasenter i
                Tyskland (Frankfurt) og Irland (Dublin).
            </Paragraph>
            <Subtitle>Hvem deler vi informasjonen med?</Subtitle>
            <Paragraph>
                Opplysningene inngår i saksbehandlingen internt hos Møller
                Mobility Group. Her vil våre bruktbileksperter først bruke
                informasjonen til å gi deg et tilbud. Dersom det blir aktuelt
                vil vi også dele informasjonen med din lokale forhandler hvor du
                kan levere bilen til gjennomgang eller levering etter at vi er
                blitt enige om pris.
            </Paragraph>
            <Paragraph>
                Vi videreformidler ikke opplysningene dine til andre
                virksomheter, med noen unntak som er nevnt under her. Vi bruker
                heller ikke opplysningene dine til markedsføring eller salg, med
                mindre du ber om å bli kontaktet angående kjøp av en ny eller
                brukt bil.
            </Paragraph>
            <Subtitle>Hvis du takker ja til pristilbudet vårt</Subtitle>
            <Paragraph $noMargin>
                Hvis du bestemmer deg for å selge bilen din til oss vil vi
                skrive kontrakt med deg. Da trenger vi mer informasjon av deg,
                som hvordan vi skal betale ut kjøpesummen, og vi må blant annet
                ta vare på kontrakten og dokumentasjon knyttet til oppgjøret.
                For at du enkelt og trygt skal kunne signere digitalt med BankID
                sender vi kontrakten til Scrive. Kontrakten vil inneholde
                følgende opplysninger om deg og bilen din:
            </Paragraph>
            <UnorderedList>
                <li>Navn</li>
                <li>Telefonnummer</li>
                <li>E-post</li>
                <li>Adresse</li>
                <li>Kontonummer for utbetaling</li>
                <li>
                    Evt. pant og heftelse på bilen (beløp og i hvilken bank)
                </li>
                <li>Registreringsnummer</li>
                <li>
                    Informasjon om bilen, som farge, kilometerstand og hva vi
                    betaler for den.
                </li>
            </UnorderedList>
            <Paragraph>
                Når kontrakten er signert vil den bli lagret hos Scrive i 14
                dager, før de legges i papirkurven og slettes 30 dager senere.
                Samtidig vil den bli sendt tilbake til oss hvor vi tar vare på
                den i henhold til bokføringsloven. På nettsiden til Scrive kan
                du lese mer om deres{' '}
                <a
                    href="https://www.scrive.com/no/terms-of-service/"
                    target="_blank"
                    rel="noreferrer"
                >
                    vilkår for bruk av Scrive sine tjenester
                </a>
                ,{' '}
                <a
                    href="https://www.scrive.com/no/privacy-notice/"
                    target="_blank"
                    rel="noreferrer"
                >
                    hvordan Scrive beskytter dine personopplysninger
                </a>{' '}
                eller kontakte de på{' '}
                <a href="mailto:support@scrive.com">support@scrive.com.</a> Hvis
                du ikke ønsker å signere kontrakten via Scrive kan du{' '}
                <a
                    href="https://mollerbil.no/finn-forhandler/?q=&b=all&s=all"
                    target="_blank"
                    rel="noreferrer"
                >
                    kontakte en av våre forhandlere og gjennomføre salget der.
                </a>
            </Paragraph>
            <Subtitle>Innlogging</Subtitle>
            <Paragraph>
                Vi har gjort det enkelt å logge inn i tjenesten vår ved å bruke
                mobilnummer og engangskode. For å gjøre dette mulig bruker vi
                Auth0 som er en tredjepartsløsning. Når du logger inn, sendes
                telefonnummeret ditt til Auth0. Du kan lese om hvordan de
                behandler dine opplysninger på{' '}
                <a
                    href="https://auth0.com/docs/secure/data-privacy-and-compliance"
                    target="_blank"
                    rel="noreferrer"
                >
                    Auth0 sine sider for personvern og overholdelse av data.
                </a>
            </Paragraph>
            <Subtitle>Hvis du allerede er registrert som kunde av oss</Subtitle>
            <Paragraph>
                Når du logges inn sjekker vi om du allerede er registrert som
                kunde hos oss. Da kobler vi henvendelsen din til den øvrige
                informasjonen vi har om deg og bilen din. Dette gjør vi i tråd
                med samtykkene du allerede har inngått som kunde. Du kan lese
                mer om dette i{' '}
                <a
                    href="https://www.mittbilhold.no/privacy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Bilhold sin personvernerklæring
                </a>
                .
            </Paragraph>
            <Subtitle>Hvis du takker nei til pristilbudet vårt</Subtitle>
            <Paragraph>
                Hvis du ikke ender opp med å selge bilen din til oss vil vi
                slette personopplysningene dine etter 6 måneder. Vi tar vare på
                annen anonymisert informasjon knyttet til henvendelsen, som
                pristilbud og metainformasjon om vår svartid så vi kan lære av
                det vi gjør og bygge en stadig bedre tjeneste.
            </Paragraph>
            <Subtitle>Hvis du har bedt om hjelp til kjøp av ny bil</Subtitle>
            <Paragraph>
                Hvis du har krysset av for at du vil ha hjelp til kjøp av ny
                bil, vil vi sende kontaktinformasjonen din til en forhandler som
                er nær deg slik at de kan hjelpe deg videre.
            </Paragraph>
            <Subtitle>Vil du se eller slette dataene dine</Subtitle>
            <Paragraph>
                Du har krav på å kunne se, eksportere og slette informasjonen vi
                har lagret om deg. Send en mail til{' '}
                <a href="mailto:kundeservice@moller.no?subject=Support: Informasjon om meg hos verdivurdering.mollerbil.no&cc=verdivurdering@moller.no">
                    kundeservice@moller.no
                </a>
                , så hjelper vi deg. Hvis du har generelle spørsmål til hvordan
                vi håndterer dine data kan du kontakte{' '}
                <a href="mailto:personvern@moller.no?subject=Support: Generelle spørsmål om personvern&cc=verdivurdering@moller.no">
                    personvern@moller.no
                </a>
            </Paragraph>
            <Paragraph>
                Hvis du ikke ender opp med å selge bilen din til oss vil vi
                slette personopplysningene dine etter 6 måneder. Vi tar vare på
                annen anonymisert informasjon knyttet til henvendelsen, som
                pristilbud og metainformasjon om vår svartid så vi kan lære av
                det vi gjør og bygge en stadig bedre tjeneste.
            </Paragraph>
            <Paragraph>
                Vil du vite mer om hvordan du kan benytte deg av rettighetene
                dine se{' '}
                <a href="https://mollerbil.no/personvern">
                    {' '}
                    Møller Bil sin personvernerklering
                </a>{' '}
                punkt 10.
            </Paragraph>
            <Subtitle>Statistikk og diagnostikk</Subtitle>
            <Paragraph>
                Vi bruker Datadog til å samle anonym diagnostikk om bruk av
                tjenesten for intern kvalitetssikring. Informasjonen om din bruk
                kan ikke knyttes tilbake til deg. Behandlingen er nødvendig for
                å ivareta en berettiget interesse for å forbedre og
                videreutvikle vår tjeneste.
            </Paragraph>
            <Paragraph>Siden ble sist oppdatert 29. oktober 2024.</Paragraph>
        </PageWrapper>
    );
};
