import { styled } from 'styled-components';
import {
    breakpointTablet,
    DisplayText,
    IconButton,
} from '@gnist/design-system';
import { tokens } from '@gnist/themes/tokens.css.js';
import i18next from 'i18next';
import { Page } from './Page';

const Header = styled.div`
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.05);

    @media screen and (min-width: ${breakpointTablet}) {
        box-shadow: none;
    }
`;

const HeaderContent = styled(Page)`
    padding: 0;
    > div {
        padding: ${tokens.spacing.xxs} ${tokens.spacing.l} ${tokens.spacing.s};
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (min-width: ${breakpointTablet}) {
            padding: ${tokens.spacing.xxs} 0 ${tokens.spacing.s};
        }
    }
`;

const PageTitle = styled(DisplayText)`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: ${tokens.typeface.size.base};
    font-weight: ${tokens.typeface.weight.medium};
    line-height: ${tokens.typeface['line-height']};

    @media screen and (min-width: ${breakpointTablet}) {
        font-size: ${tokens.typeface.size.m};
    }
`;

const BackButton = styled(IconButton)`
    position: absolute;
`;

interface PageHeaderProps {
    readonly titleTextKey: string;
    readonly backButtonOnClick?: () => void;
    readonly backButtonTextKey?: string;
}

export const PageHeader = ({
    titleTextKey,
    backButtonOnClick,
    backButtonTextKey = '',
}: PageHeaderProps) => {
    const isMobile = matchMedia(`(max-width: ${breakpointTablet})`).matches;

    return (
        <Header>
            <HeaderContent>
                {!!backButtonOnClick && (
                    <BackButton
                        onClick={backButtonOnClick}
                        icon="arrow_backward"
                        label={i18next.t(backButtonTextKey)}
                        showLabel={isMobile ? 'none' : 'right'}
                    />
                )}
                <PageTitle>{i18next.t(titleTextKey)}</PageTitle>
            </HeaderContent>
        </Header>
    );
};
